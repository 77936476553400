import React from 'react';

import './Error.css';

const Error = () => {
  return (
    <div className='app'>
      <div className='app__error'>
        <h1 className='bold-text'>ERROR</h1>
        <h3>Page not found</h3>
      </div>
    </div>
  )
}

export default Error