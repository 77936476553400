import React from "react";

import "./Gallery.css";
import { images } from "../../constants";

const Gallery = () => {
  const imageList = [images.img1, images.img6, images.img3];

  return (
    <div className="app">
      <div className="app__gallery">
        <div className="gallery-slideshow">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner">
              {imageList.map((img, index) =>
                index == 0 ? (
                  <div class="carousel-item active">
                    <img src={img} class="d-block w-100" alt="..." />
                  </div>
                ) : (
                  <div class="carousel-item">
                    <img src={img} class="d-block w-100" alt="..." />
                  </div>
                )
              )}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="gallery-frames">
          {imageList.map((img, index) => (
            <div
              className="frame"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index}
            >
              <img src={img} alt={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
