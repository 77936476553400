import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

import { images } from '../../constants';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: ''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData.current, 'YOUR_PUBLIC_KEY')
      .then(() => {
          setLoading(false);
          setIsFormSubmitted(true);
      }, (error) => {
          console.log(error.text);
          setIsFormSubmitted(false);
      });
  }

  return (
    <div className="app">
      <div className='app__contact'>
      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.mail} alt='email' />
          <a href='mailto:testmail@gmail.com' className='p-text'>testmail@gmail.com</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.phone} alt='phone' />
          <a href='tel: +(717) 550-1675' className='p-text'>+(717) 550-1675</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.instagram} alt='instagram' />
          <a href='https://www.instagram.com/testpofile/' target='_blank' rel="noreferrer" className='p-text'>testprofile_</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.facebook} alt='facebook' />
          <a href='https://www.facebook.com/user/testprofile_' rel="noreferrer" target='_blank' className='p-text'>testprofile_</a>
        </div>
      </div>
      {!isFormSubmitted ? 
        <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input className='p-text' type='text' placeholder='Your name' name='name' value={name} onChange={handleChangeInput}/>
          </div>
          <div className='app__flex'>
            <input className='p-text' type='email' placeholder='Your email' name='email' value={email} onChange={handleChangeInput}/>
          </div>
          <div className='app__flex'>
            <textarea className='p-text' placeholder='Your message' value={message} name='message' onChange={handleChangeInput} />
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send Message'}</button>
        </div>
        :
        <div>
          <h3>Thank you!</h3>
        </div>
      }
      </div>
    </div>
  )
}

export default Contact