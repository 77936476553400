import React from 'react'

import './Footer.css';

const Footer = () => {
  return (
    <div className="app__footer">
      <div class="copyright text-center">© 2023 Lorem Ipsum</div>
    </div>
  )
}

export default Footer