import React from 'react';

import './WhatsAppButton.css';
import { images } from '../../constants';

const WhatsAppButton = () => {
  return (
    <div id="whatsapp-button">
      <a href="https://wa.me/your-phonenumber" target="_blank">
        <img src={images.whatsapp} alt="WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsAppButton;
