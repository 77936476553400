import React, { useState } from "react";
import { motion } from "framer-motion";

import { NavigationDots } from "../../components";
import { images } from "../../constants";
import "./Home.css";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageList = [
    images.img1,
    images.img6,
    images.img3,
    images.img1,
    images.img6,
    images.img3,
  ];

  const handleDotHover = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="app">
      <div className="app__home">
        <div className="app__home-landing" id="home">
          <div className="app__home-content">
            <div className="video-container">
              <video
                src={images.video}
                type="video/mp4"
                loop={true}
                controls={false}
                muted
                autoPlay
              />
            </div>
            <div className="video-cover">
              <div className="landing-text">
                <h1 className="mb-4">Lorem Ipsum</h1>
                <p>
                  Lorem ipsum dolor sit amet. Ea quis ipsam et omnis voluptas et
                  enim iure ut molestias minus et dolor omnis est adipisci
                  praesentium est fugit dolor. Et ducimus numquam et expedita
                  voluptatem qui molestiae laboriosam.
                </p>
              </div>
            </div>
            <NavigationDots className="nav-dots" active={"home"} />
          </div>
        </div>
        <div className="app__home-about" id="about">
          <div className="app__home-content">
            <div className="app__home-about-content"></div>
            <div className="about-text">
              <motion.div
                whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                transition={{ duration: 0.5 }}
                className="about-div"
              >
                <h1>Lorem Ipsum</h1>
                <p className="my-2 bold-text">About Us</p>
                <p>
                  Lorem ipsum dolor sit amet. Ea quis ipsam et omnis voluptas et
                  enim iure ut molestias minus et dolor omnis est adipisci
                  praesentium est fugit dolor. Et ducimus numquam et expedita
                  voluptatem qui molestiae laboriosam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  bibendum magna non est eleifend lacinia. Proin orci enim,
                  convallis nec dapibus placerat, gravida id justo. Pellentesque
                  nec dolor nec urna posuere placerat. Praesent luctus massa id
                  purus efficitur, eget elementum nisl mollis. Nam ultricies
                  euismod tortor, eleifend pharetra metus sollicitudin sed. Cras
                  in convallis nibh. Sed viverra metus eget faucibus mollis.
                  Vestibulum luctus fringilla feugiat.
                </p>
              </motion.div>
            </div>
            <NavigationDots active={"about"} />
          </div>
        </div>
        <div className="app__home-gallery" id="gallery">
          <div className="app__home-content">
            <div className="app__home-gallery-content">
              <div>
                <h1 className="bold-text">Preview</h1>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div id="image-carousel">
                {imageList.map((image, index) => (
                  <img
                    key={index}
                    className="image"
                    alt={`Gallery ${index + 1}`}
                    src={image}
                    draggable="false"
                    style={{
                      display: index === currentIndex ? "block" : "none",
                      width: "100%", // Set a consistent width
                      height: "auto", // Maintain the aspect ratio
                      marginBottom: "2rem",
                      border: "2px solid var(--secondary-color)", // Add a rounded border
                      borderRadius: "10px",
                    }}
                  />
                ))}
              </div>
              <div id="timeline" className="thumbnail-timeline">
                {imageList.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className={`thumbnail${
                      index === currentIndex ? " active" : ""
                    }`}
                    onMouseEnter={() => handleDotHover(index)}
                  />
                ))}
              </div>
            </div>
            <NavigationDots active={"gallery"} />
          </div>
        </div>
        <div className="app__home-offers" id="offers">
          <div className="app__home-content">
            <div className="app__home-about-content">
              <div className="offer-text">
                <motion.div
                  whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="offer-div"
                >
                  <h1 className="bold-text mb-5">What we offer</h1>
                  <div className="offer-item">
                    <img src={images.scissor} alt="Scissors"></img>
                    <div>
                      <h4>Lorem ipsum</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eli
                      </p>
                    </div>
                  </div>
                  <div className="offer-item">
                    <img src={images.paintBucket} alt="Bucket"></img>
                    <div>
                      <h4>Lorem ipsum</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eli
                      </p>
                    </div>
                  </div>
                  <div className="offer-item">
                    <img src={images.beard} alt="Beard"></img>
                    <div>
                      <h4>Lorem ipsum</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eliLorem ipsum dolor sit amet, consectetur adipiscing
                        eli
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
            <NavigationDots active={"offers"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
